import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import ListTile from '../components/listTile'
import { RiFilter2Fill } from '@react-icons/all-files/ri/RiFilter2Fill'
import { CgClose } from '@react-icons/all-files/cg/CgClose'
import { BiPlus } from '@react-icons/all-files/bi/BiPlus'
import { IoMdArrowDropdown } from '@react-icons/all-files/io/IoMdArrowDropdown'
import SEO from '../components/seo'
import Breadcrumbs from '../components/breadcrumbs'
import GoToTop from '../components/goToTop'

export const query = graphql`
query($slug: String!, $parentCategory: String!, $name: String!){
  subCategory: strapiCategory(slug: {eq: $slug}) {
    slug
    name
    path
    parentCategory
    description
    metaTitle
    metaDescription
    banner {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
     }
    products {
      slug
      model
      url
      searchTitle
      websiteActive
      category{
        name
      }
      specifications {
        strapi_json_value {
          attribute
          value
          filter
        }
      }
      images {
        name
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
  parent: strapiCategory(name: {eq: $parentCategory}){
    name
    path
    slug
    banner {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    bannerMobile {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
  childProducts: allStrapiProduct(
      filter: {category: {name: {in: [$name, $parentCategory]}}}
    ) {
      nodes {
        slug
        model
        searchTitle
        websiteActive
        url
        images {
          name
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  sisterCategories: allStrapiCategory(
    filter: {
      level: {gte: 2}, 
      parentCategory: {eq: $parentCategory}
    }
  ) {
    nodes{
      slug
      path
      name
      parentCategory
    }
  }
  childCategories: allStrapiCategory(
    filter: {
      level: {gte: 2}, 
      parentCategory: {eq: $name}
    }
  ) {
    nodes{
      slug
      path
      name
      parentCategory
      products {
        slug
        model
        searchTitle
        url
        websiteActive
        category {
          name
        }
        specifications {
          strapi_json_value {
            attribute
            value
            filter
          }
        }
        images {
          name
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}`

export default function SubCategory({ data, pageContext }) {
  const subCategory = data.subCategory;
  const sisterCategories = data.sisterCategories.nodes;
  const childCategories = data.childCategories.nodes;
  const parent = data.parent

  //const childProducts = data.childProducts.nodes;
  let products = subCategory.products 
  if (subCategory.products.length < 1) {
    childCategories.forEach(category => {
      category.products.forEach(product => products.push(product))
    })
  }
  if (childCategories.length < 0) {
    products = subCategory.products
  }
  products=products.filter((product)=>product.websiteActive==true)

  if(subCategory.name=="Underground Cable And Fault Locators"){
    // sort selected model on top of category list
    const models = ["G9320", "G9325", "G9326"];
    const priorityModels = new Set(models);
    products.sort((a, b) => {
      const isAPriority = priorityModels.has(a.model);
      const isBPriority = priorityModels.has(b.model);
  
      if (isAPriority && isBPriority) {
          return models.indexOf(a.model) - models.indexOf(b.model);
      }
      if (isAPriority) return -1;
      if (isBPriority) return 1;
      return 0;
    });
  }
  const [filteredProducts, setFilteredProducts] = useState([]);
  const allFilters = [];
  const allSpecs = [];
  const listed = [];
  const filterArray = []
  const [checked, setChecked] = useState([]);
  const [childCheck, setChildCheck] = useState([]);
  const [specFilteredProducts, setSpecFilteredProducts] = useState([])
  const specFilterArray = []

  useEffect(() => {
    filterArray.length = 0;

    checked.forEach(selected => {
      products.forEach(product => {
        if (selected.filter === "product-type" && selected.value == product.category.name && !filterArray.includes(product)) {
          filterArray.push(product)
        }
      })
    })

    checked.forEach(selected => {
      if (filteredProducts.length < 1) {
        products.forEach(product => {
          product.specifications?.strapi_json_value.forEach(spec => {
            if (selected.filter === spec.attribute && spec.value.trim() === selected.value.trim() && !specFilterArray.includes(product)) {
              specFilterArray.push(product)
            }
          })
        })
      }
      filteredProducts.forEach(product => {
        product.specifications?.strapi_json_value.forEach(spec => {
          if (selected.filter === spec.attribute && spec.value.trim() === selected.value.trim() && !specFilterArray.includes(product)) {
            specFilterArray.push(product)
          }
        })
      })
    })

    setFilteredProducts(filterArray)
    setSpecFilteredProducts(specFilterArray)
  }, [checked])

  filteredProducts.forEach(product => {
    product.specifications?.strapi_json_value.filter(spec => spec.filter).forEach(spec => {
      if (!allFilters.includes(spec.attribute)) {
        allFilters.push(spec.attribute)
      }
      allSpecs.push(spec)
    })
  });

  if (filteredProducts.length < 1) {
    products.forEach(product => {
      product.specifications?.strapi_json_value.filter(spec => spec.filter).forEach(spec => {
        if (!allFilters.includes(spec.attribute)) {
          allFilters.push(spec.attribute)
        }
        allSpecs.push(spec)
      })
    });
  }

  const handleCheck = (event, filter) => {

    var updatedList = [...checked]
    if (filter == "product-type") {
      console.log("selected a category filter")
      updatedList = updatedList.filter(item => (item.filter === "product-type"))
      specFilteredProducts.length = 0
      console.log(updatedList)
    }
    if (event.target.checked) {
      updatedList = [...checked, { filter: filter, value: event.target.value }]
    } else {
      //updatedList.splice(checked.indexOf([filter, event.target.value]), 1)
      updatedList = updatedList.filter(item => (item.filter !== filter || item.value !== event.target.value))
    }
    setChecked(updatedList)
  }

  const handleChildCheck = (event) => {
    var updatedList = [...childCheck]
    if (event.target.checked) {
      updatedList = [...childCheck, event.target.value]
    } else {
      updatedList.splice(childCheck.indexOf(event.target.value), 1)
    }
    setChildCheck(updatedList)
  }

  const handleFilterView = (event, name) => {
    document.getElementById(name).classList.toggle("hidden")
    // event.target.classList.toggle("text-primary")
  }

  const handleShowMobileFilters = () => {
    document.getElementById("filters").classList.toggle("hidden")
  }

  const [showMore, setShowMore] = useState(false);
  const para = subCategory.description || ""
  const shortened = showMore === true ? para : (para.slice(0, 100) + "...");

  return (
    <Layout>
      <SEO
        title={subCategory.metaTitle}
        description={subCategory.metaDescription}
      />
      <div className="hidden md:block md:h-64 bg-secondary">
        <GatsbyImage
          image={getImage(parent?.banner?.localFile)}
          alt={subCategory.name}
          className="h-full w-full"
        />
      </div>
      <div className="w-full md:hidden">
        <GatsbyImage
          image={getImage(parent?.bannerMobile?.localFile)}
          alt={subCategory.name}
          className="h-full w-full"
        />
      </div>
      <div className="px-4 md:px-12">
        <div className="text-xs md:text-sm pt-5 pb-5 md:pb-9">
          {/* <Breadcrumb
            crumbs={crumbs}
            crumbLabel={subCategory.name}
          /> */}
          <Breadcrumbs
            trail={[{ title: parent.name, link: "/" + parent.path }, { title: subCategory.name, link: "/" + subCategory.path }]}
          />
        </div>
        <h1 className=' text-lg md:text-xl 2xl:text-2xl text-primary pb-2'>{subCategory.name}</h1>
        <p className="hidden md:block">{para}</p>
        {para.length > 0 &&
          <div>
            <p className='md:hidden'>{shortened}</p>
            <button className="text-sm underline md:hidden pt-2" onClick={() => setShowMore(!showMore)}>{showMore ? "Show Less" : "Read More"}</button>

          </div>
        }

        <button className="font-bold md:hidden mt-6 flex items-center gap-2 justify-center hover:bg-transparent border border-black w-24 text-lg  rounded py-2"
          onClick={handleShowMobileFilters}
        >
          Filters
          <RiFilter2Fill />
        </button>
        <div className="w-full md:flex py-8">

          {/* fliters on the side */}
          <div id="filters"
            className="overflow-y-auto h-[500px] md:h-full fixed px-4 py-8 md:px-0 md:py-0 hidden top-0 left-0 z-20 md:z-0 min-h-full w-screen bg-white lg:relative lg:min-h-full lg:block lg:w-1/4 md:border-r border-secondary "
          >
            <div className="w-full flex flex-row-reverse">
              <button
                className='text-4xl md:hidden flex flex-row-reverse'
                onClick={handleShowMobileFilters}
              >
                <CgClose />
              </button>
            </div>

            {childCategories.length > 0 &&
              <div className='mt-6 md:mt-0 mb-12'>
                <div className="flex items-center w-[95%] justify-between">
                  <h2 className=" text-lg md:text-xl 2xl:text-2xl text-primary pb-2">Product Type</h2>
                  <button
                    onClick={(event) => handleFilterView(event, "productType")}
                    className='py-1 flex justify-center w-6 h-6 border border-black rounded'
                  >
                    <IoMdArrowDropdown />
                  </button>
                </div>

                <ul id="productType">
                  {childCategories.sort().map((child, i) => {
                    return (
                      <li key={i}>
                        <label className='cursor-pointer flex items-center py-0.5'>
                          <input
                            name={child.name}
                            type="checkbox"
                            onChange={event => handleCheck(event, "product-type")}
                            //onClick={() => filterHandler(filter, spec.value)}
                            value={child.name}
                          />
                          {child.name}
                        </label>
                      </li>
                    )
                  })}
                </ul>
              </div>
            }

            {allFilters.length > 0 &&
              <h2 className="text-lg md:text-lg 2xl:text-2xl text-primary pb-6">Filters</h2>
            }
            <ul>
              {allFilters.sort().map((filter, j) => {
                listed.length = 0;
                return (
                  <div className='border-t border-secondary pt-6 mb-6 w-[95%]'>
                    <div className='flex items-center justify-between pb-2'>
                      <h3 className="text-lg md:text-lg 2xl:text-2xl text-primary capitalize">{filter}</h3>
                      <button
                        onClick={(event) => handleFilterView(event, j)}
                        className='py-1 flex justify-center w-6 h-6 border border-black rounded'
                      >
                        <IoMdArrowDropdown />
                      </button>
                    </div>
                    <form id={j} className="hidden">

                      {allSpecs.sort((a, b) => (a.value > b.value ? 1 : -1)).map((spec, i) => {

                        if (spec.attribute == filter && !listed.includes(spec.value.trim())) {
                          listed.push(spec.value.trim())
                          return (
                            <li key={i}>
                              <label className='cursor-pointer flex items-center py-0.5'>
                                <input
                                  name={spec.value}
                                  type="checkbox"
                                  onChange={event => handleCheck(event, filter)}
                                  value={spec.value}
                                />
                                {spec.value}
                              </label>
                            </li>
                          )
                        }
                      })}
                    </form>
                  </div>
                )
              })}

            </ul>
            <div className="md:hidden flex flex-row-reverse">
              <button className="w-36 mt-6 bg-primary text-white text-lg rounded py-2"
                onClick={handleShowMobileFilters}
              >
                Apply
              </button>
            </div>
          </div>
          <GoToTop />
          {/* List of Products */}
          <div className="w-full lg:ml-6">
            {/* <h1>selected category: {childCheck}</h1> */}
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-3 md:gap-0">
              {specFilteredProducts.map(product => <ListTile product={product} />)}
              {specFilteredProducts < 1 &&
                filteredProducts.map(product => <ListTile product={product} />)
              }
              {specFilteredProducts < 1 && filteredProducts < 1 &&
                products.map(product => <ListTile product={product} />)
              }
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
